<template>
    <div id="card-clientes-mobile" 
        class="
            col-sm-12 row 
            nopadding 
            mt-3 d-flex 
            align-items-center 
            justify-content-center
        "
        >
        <b-carousel 
            id="slideClientes-mobile" 
            background="transparent" 
            :interval="10000"  
            indicators
            img-height="500">
            <!-- macromaq -->
            <b-carousel-slide>
                <template v-slot:img>
                    <div class="col-sm-12 nopadding d-flex align-items-center justify-content-center">
                        <div class="corpo-depoimento row d-flex align-items-center justify-content-center">
                            <div class="img-depoimento col-sm-12 row nopadding justify-content-end">
                                <div class="col-sm-3 nopadding div-img-logo">
                                    <img src="@/assets/images-clientes/logoEmpresaMacromaq.png" 
                                        class="img-responsive" width="100%" height="100%"
                                        alt="Logo da empresa Macromaq. Cliente da Egle Track"
                                    >
                                </div>
                            </div>
                            <div class="texto-depoimento col-sm-12 row nopadding d-flex justify-content-center">
                                <div class="text-left texto-principal">
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z" 
                                        />
                                    </svg>
                                    O sistema de rastreamento trouxe mais 
                                    <span class="texto-destaque texto-azul">
                                        praticidade no controle da frota técnica e comercial, permitindo saber a 
                                        localização exata dos colaboradores,
                                    </span> o que resultou também em um melhor controle das multas e dos 
                                    riscos de acidentes, além de melhor visibilidade aos bens da companhia, 
                                    trazendo mais segurança ao negócios.
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z" 
                                        />
                                    </svg>
                                </div>
                                <div class="texto-secundario d-flex align-items-center justify-content-center">
                                    <div class="row d-flex justify-content-center"> 
                                        <span class="row col-sm-12 text-center nopadding">
                                            <span class="col-sm-12 texto-destaque texto-azul">
                                                André Bender
                                            </span>
                                            <span class="small texto-destaque text-muted col-sm-12">
                                                Gerente de locações
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-carousel-slide>
            <!-- trr gral -->
            <b-carousel-slide>
                <template v-slot:img>
                    <div class="col-sm-12 nopadding d-flex align-items-center justify-content-center">
                        <div class="corpo-depoimento row d-flex align-items-center justify-content-center">
                            <div class="img-depoimento col-sm-12 row nopadding justify-content-end">
                                <div class="col-sm-3 nopadding div-img-logo">
                                    <img 
                                        src="@/assets/images-clientes/logoEmpresaTrrGral.png" 
                                        class="img-responsive" width="100%" height="100%"
                                        alt="Logo da empresa TRR Gral. Cliente da Egle Track"
                                    >
                                </div>
                            </div>
                            <div class="texto-depoimento col-sm-12 row nopadding d-flex justify-content-center">
                                <div class="text-left texto-principal">
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z"
                                        />
                                    </svg>
                                    Vários controles em um único sistema. <span class="texto-azul texto-destaque"> 
                                        A Eagle oferece a vantagem da jornada de trabalho </span>
                                    dos motoristas poder ser padronizada de acordo com a particularidade da empresa, 
                                    a localização dos veículos em tempo real e também a possibilidade de controles de 
                                    documentação dos veículos, manutenções, e documentação dos motoristas. 
                                    Além de possuir um suporte técnico ágil e disponível.
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z"
                                        />
                                    </svg>
                                </div>
                                <div class="texto-secundario d-flex align-items-center justify-content-center">
                                    <!-- 
                                        <img 
                                            src="@/assets/images-clientes/andremacromaq.jpg" 
                                            width="100%" height="100%"> 
                                    -->
                                    <div class="row d-flex justify-content-center">
                                        <span class="row col-sm-12 text-center nopadding">
                                            <span class="col-sm-12 texto-destaque texto-azul">
                                                Jackson A. Cadore Pires 
                                            </span>
                                            <span class="small texto-destaque text-muted col-sm-12">
                                                Gerente de Frota
                                            </span>
                                        </span>
                                        <span class="row col-sm-12 text-center">
                                            <span class="col-sm-12 nopadding texto-destaque texto-azul">
                                                Vinicius Gabriel Saggioratto
                                            </span>
                                            <span class="small text-muted texto-destaque col-sm-12 nopadding"> 
                                                Analista de Logística e Transporte 
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-carousel-slide>
            <!-- irani -->
            <b-carousel-slide>
                <template v-slot:img>
                    <div class="col-sm-12 nopadding d-flex align-items-center justify-content-center">
                        <div class="corpo-depoimento row d-flex align-items-center justify-content-center">
                            <div class="img-depoimento col-sm-12 row nopadding justify-content-end">
                                <div class="col-sm-3 nopadding div-img-logo">
                                    <img 
                                        src="@/assets/images-clientes/logoEmpresaIrani.png" 
                                        class="img-responsive" 
                                        width="100%" height="100%"
                                        alt="Logo da empresa Celulose Irani. Cliente da Egle Track"
                                    >
                                </div>
                            </div>
                            <div class="texto-depoimento col-sm-12 row nopadding d-flex justify-content-center">
                                <div class="text-left texto-principal">
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z"
                                        />
                                    </svg>
                                    Com os módulos da Eagle Track foi possível ter um maior 
                                    controle da quilometragem rodada e excessos de velocidades,
                                    além de <span class="texto-destaque texto-azul"> 
                                        identificar os condutores que cometeram infrações de trânsito, </span> 
                                    podendo cobrar dos motoristas que não seguirem 
                                    as medidas estipuladas pela empresa. Aderir esse serviço trouxe um maior 
                                    <span class="texto-destaque texto-azul">
                                        controle e segurança aos funcionários e a empresa.
                                    </span>
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z" 
                                        />
                                    </svg>
                                </div>
                                <div class="texto-secundario d-flex align-items-center justify-content-center">
                                    <!-- 
                                        <img 
                                            src="@/assets/images-clientes/andremacromaq.jpg" 
                                            width="100%" height="100%"> 
                                    -->
                                    <div class="row d-flex justify-content-center">
                                        <span class="row col-sm-12 text-center nopadding">
                                            <span class="col-sm-12 texto-destaque texto-azul">
                                                Marlon Josias da Silva
                                            </span>
                                            <span class="small texto-destaque text-muted col-sm-12">
                                                Analista Administrativo
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-carousel-slide>
            <!-- traçado -->
            <b-carousel-slide>
                <template v-slot:img>
                    <div class="col-sm-12 nopadding d-flex align-items-center justify-content-center">
                        <div class="corpo-depoimento row d-flex align-items-center justify-content-center">
                            <div class="img-depoimento col-sm-12 row nopadding justify-content-end">
                                <div class="col-sm-3 nopadding div-img-logo">
                                    <img 
                                        src="@/assets/images-clientes/logoEmpresaTracado.png" 
                                        class="img-responsive" width="100%" height="100%"
                                        alt="Logo da empresa Traçado. Cliente da Egle Track"    
                                    >
                                </div>
                            </div>
                            <div class="texto-depoimento col-sm-12 row nopadding d-flex justify-content-center">
                                <div class="text-left texto-principal">
                                    <svg style="width:20px;height:20px;" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z"
                                        />
                                    </svg>
                                    Agora temos o controle adequado sobre as horas trabalhadas dos motoristas, 
                                    como: horas extras e tempo de espera. 
                                    <span class="texto-destaque texto-azul">
                                        A adesão do módulo trouxe redução de custo e mais 
                                        segurança para o motorista e para empresa.
                                    </span>
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z" 
                                        />
                                    </svg>
                                </div>
                                <div class="texto-secundario d-flex align-items-center justify-content-center">
                                    <div class="row d-flex justify-content-center">
                                        <span class="row col-sm-12 borda-divisa text-center nopadding">
                                            <span class="col-sm-12 texto-destaque texto-azul">
                                                Freddy Luiz Ersi Alves
                                            </span>
                                            <span class="small texto-destaque text-muted col-sm-12">
                                                Administrativo de Obras
                                            </span>
                                        </span>
                                        <span class="row col-sm-12 text-center">
                                            <span class="col-sm-12 nopadding texto-destaque texto-azul">
                                                Eduardo Kistemacher 
                                            </span>
                                            <span class="small text-muted texto-destaque col-sm-12 nopadding"> 
                                                Administrativo de Monitoramento
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>
<script>
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            mobile:false,
        }
    },
    
    // mounted() {
    //     var windowWidth = window.innerWidth
    //     // console.log(windowWidth)
    // },
})
</script>
<style lang="scss">
    #card-clientes-mobile{
        background-color: #2c3d47;
        height: 550px;
        width: 100%;

        #slideClientes-mobile{
            .carousel-indicators {
                transform: translateY(35px);
                li{
                    background-color: #c5c5c5;
                    &:hover{
                        background-color: black;
                        transition: .5s;
                        transform: scale(1.2);
                    }
                }
                .active {
                    background-color: #40a8d6 !important;
                }
            }
        }
        
        .corpo-depoimento{
            border-radius: 20px;
            width: 75%;
            height: 500px;
            background-color: #FFF;
            .img-depoimento{
                width: 450px;
                border-radius: 10px;
                padding: 2px;
                
                img{
                    background-color: #FFF;
                    object-fit: cover;
                    border-radius: 10px;
                }

                .div-img-logo{
                    width: 45%;
                    height: 45%;
                }
                
                

            }
            .texto-depoimento{
                transform: translateX(-0%) translateY(-3%);
                border-radius: 10px;
                font-style: italic;
                height: 80%;
                
                .texto-azul{
                    color: #14afe2;
                }
                .texto-destaque{
                    font-family: nexabold;
                }
                .texto-principal{
                    padding: 0px 20px 0px 20px;
                    width: 100%;
                }
                .texto-secundario{
                    white-space: nowrap;
                    width: 100%;
                }

                .borda-divisa{
                    // border-right: 1px solid #838a91;
                }
            }
        }
    }
</style>
