<template>
    <div id="occupationArea" class="col-sm-12 justify-content-center nopadding row margin-bot">
        <div class="col-sm-12 row mb-5 nopadding justify-content-center">
            <div class="col-sm-10 justify-content-center nopadding row">
                <div class="col-sm-8 divTitleModule">
                    <div class="borda-5px-verde">
                        Conheça a
                            <span class="cor-azul">
                                transformação
                            </span>
                        dos nossos clientes
                    </div>
                </div>
                <div class="col-sm-4 divButtonModule">
                    <button class="buttonModule" @click="irParaContato">
                        Quero ser cliente
                    </button>
                </div>
            </div>
            <div class="col-sm-12 row nopadding">
                <slideDepoimento v-if="!mobile"/>
                <slideDepoimentoMobile v-else/>
            </div>
        </div>
        <!-- mapa azul -->
        <div class="col-sm-12 div-mapa justify-content-center row fudo-azul fade-in mt-5">
            <div class="col-sm-12 col-md-2"/>
            <div class="div-texto-mapa col-sm-12 col-md-5" >
                <h3 class="titulo">
                    <strong>
                        Onde quer que seja, estaremos prontos para atender!
                    </strong> 
                </h3>
                <div class="descricao">
                    <p>
                        Atuação Nacional com atendimento local para sua frota onde quer que ela esteja. <br>
                        Nossa rede de técnicos especializados está presente em todo Brasil para garantir um 
                        rápido tempo de resposta a chamados e solicitações.
                    </p>
                </div>
            </div>
            <div class="col-sm-12 col-md-5 divImageBrasil">
                <img 
                    src="@/assets/images/mapaSemMarker.png" 
                    class="divImageBr marker"
                    alt="Imagem mostrando o mapa do Brasil"
                >
                <img 
                    src="@/assets/images/mapaSoMarker.png" 
                    class="divImageBr pular"
                    alt="Imagem mostrando o marcadores no formato do mapa do Brasil"
                >
            </div>
        </div>
    </div>    
</template>

<script lang="js">
import Vue from 'vue'
export default Vue.extend({
    components:{
        // src/components/Atons/slideDepoimento.vue
        slideDepoimento: require('@/components/ComponentsPages/Depoimentos/slideDepoimento').default,
        slideDepoimentoMobile: require('@/components/ComponentsPages/Depoimentos/slideDepoimento-mobile').default,
    },

    data(){
        return{
            isActive: false,
            mobile: false,
            // selected: 1,
            // options: [
            //     { text: '', value: 1 },
            //     { text: '', value: 2 },
            //     { text: '', value: 3 },
            //     { text: '', value: 4 }
            // ]
        }
    },
    
    mounted() {
    },
    
    methods:{
        veTamanhoDaTela(){
            if(window.innerWidth > 700){
                this.mobile = false
            }else{
                this.mobile = true
            }
            // console.log('mudou o tamanho', window.innerWidth)
        },

        irParaContato(){
            // window.scroll(0, 1)
            this.$router.push('/contato')
        },

        handleScroll(){
            const els = document.querySelectorAll('.div-mapa')
            els.forEach((el) => {
                if(el.getBoundingClientRect().top <= 780){
                    el.style.animationPlayState = 'running'
                }
            })
        }
    },

    created(){
        window.addEventListener('resize', this.veTamanhoDaTela)
        window.addEventListener('scroll', this.handleScroll)
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('resize', this.veTamanhoDaTela)

    }
})
</script>

<style lang="scss">
    @media screen and (max-width: 450px) {
        .img-empresa{
            width: 200px !important;
            height:200px !important;           
        }
    }
    @media screen and (min-width: 500px) {
        .img-empresa{
            width: 240px !important;
            height:250px !important;          
        }
    }

    .fade-in {
        animation: fade-in 0.8s cubic-bezier(.17,.67,.49,1.02);
        animation-play-state: paused;
        animation-fill-mode: forwards;
    }
    .buttonContact{
        font-size: 17px;
        color: #fff;
        text-decoration: none;
        width: 75%;
        padding: 10px;
        background-image: radial-gradient(#2b9cbb, #32b8dd);
        border: none;
    }
    .margin-top-10px{
        margin-top: 10px
    }
    .fudo-azul{
        background-color: rgba(5, 61, 114, 0.900);
    }
    .margin-bot{
        margin-bottom: 10rem;
    }
    #occupationArea{
        padding-top: 30px !important;

        .divTitleModule{
            max-height: calc(25vh - 10vw);
            font-family: 'nexabold';
            font-size: 30px;
            color: #373d43;
            .borda-5px-verde{
                border-bottom: 5px solid #075f6f;
            }
            .cor-azul{
                color: #14afe2;
            }
        }
        .divButtonModule{
            .buttonModule{
                font-size: 17px;
                color: #fff;
                text-decoration: none;
                width: 75%;
                padding: 10px;
                background: #32b8dd;
                border: none;
            }
        }
        .divTitleOccupationArea{
            font-size: 45px;
            font-weight: bold;
        }
        .div-mapa{
            max-height:55%;
            padding-top: 10px;
            z-index: 999;
            position: relative;
            .div-texto-mapa{
                margin-top: 10px;
                z-index: 1;
                font-family: 'nexabook';
                text-align: left;
                color:#FFF;
                transition: 1s;
                font-size: clamp(16px, calc(0.1em + 1.4vw), 35px);
                h3{
                    text-align: left !important;
                    font-size: clamp(20px, calc(0.1em + 3vw), 40px);
                    font-family: 'nexabold';
                }
            }
            .divImageBrasil{
                max-height: 10%;
                .divImageBr{
                    min-width: 290px;
                    width: 80%;
                    max-width: 690px;
                }
                img{
                    transform: translateY(-5.8em); 
                    transition: 1s;
                }
                .pular{
                    position: absolute;
                    transform: translateX(-99%) translateY(-6.4em) scale(1.2); 
                    opacity: 0;
                    transition: 500ms;
                }
                &:hover{
                    img{
                        transform: translateY(-3em) scale(1.2);
                    } 
                    .pular{
                        opacity: 1;
                        animation: mapaMarker 500ms linear alternate-reverse infinite;
                    }
                }
            }
        }
        .card-container{
            ol{
                li{
                    background-color:black;
                }
            }
            .caixa-apresentacao{
                padding-top: 15px;
                min-height:200px;
                margin: 20px !important;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-sizing: border-box;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
                i.fonte-italica{
                    font-size: 15px;
                }
                .fonte-normal{
                    text-align: center;
                }
            }
        }
        .title{
            color: #373d43;
            font-family:'nexabold';
            .fonte-azul{
                color: rgb(20, 175, 226) !important;
            }
        }
        .divTextOccupationArea{
            text-align: left;
            margin-left: -35px !important;
            padding-top: 50px;
            font-size: 25px;
        }
        .pefil{
            // .img-perfil{
            //     position: relative;
            //     .image-perfil{
            //         border: 5px solid #fff;
            //         border-radius: 90%;
            //     }
            // }
        }
        .divTitleClientCases {
            font-size: 30px;
            font-weight: bold;
        }
    }
@keyframes fade-in {
    0% { 
        opacity: 0;
        transform: translateY(50%);
    }
    100% { 
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes mapaMarker {
    to{
        transform: translateX(-98%) translateY(-4.2em) scale(1.2);
    }
    from{
        transform: translateX(-98%) translateY(-4em)  scale(1.2);
    }
}
</style>
