<template>
    <div class="col-sm-10 row justify-content-center" id="nosso-app">
        <div class="texto-app col-sm-12 margin-top-bastante">
            <span class="titulo text-justify">
                Com o Eagle Finder, é possível ter visibilidade da sua frota onde estiver
            </span>
            <br>
        </div>
        <div class=" col-md-12 row justify-content-center">
            <div class="col-sm-12 images-app d-flex justify-content-center">
                <input type="radio" name="position" ref="radio1" />
                <input type="radio" name="position" ref="radio2" checked/>
                <input type="radio" name="position" ref="radio3" />
                <section id="carousel">
                    <div class="item cursor-pointer" v-on:click="imgClick('radio1')" v-touch:swipe="touchLoco">
                        <img 
                            src="@/assets/images/image-app-2.png" 
                            alt="Celular Iphone mostrando uma rota criada no aplicativo Eagle Finder"
                        >
                    </div>
                    <div class="item cursor-pointer" v-on:click="imgClick('radio2')" v-touch:swipe="touchLoco">
                        <img 
                            src="@/assets/images/image-app-1.png" 
                            alt="Celular Iphone mostrando o aplicativo Eagle Finder"

                        >
                    </div>
                    <div class="item cursor-pointer" v-on:click="imgClick('radio3')" v-touch:swipe="touchLoco">
                        <img 
                            src="@/assets/images/image-app-3.png" 
                            alt="Celular Iphone mostrando uma rota criada no aplicativo Eagle Finder"
                        >
                    </div>
                </section>
            </div>
            <div class="col-sm-12 div-texto">
                <div class="texto col-sm-12 row">
                    <span class="col-sm-12 arrow1 nopadding">
                        <b-icon icon="arrow-return-right" variant="info" aria-hidden="true"/>
                        Visualize a última localização dos veículos 
                    </span>
                    <span class="col-sm-12 arrow2">
                        <b-icon icon="arrow-return-right" variant="info" aria-hidden="true"/>
                        Verifique o rastro, paradas e picos de velocidade
                    </span>
                    <span class="col-sm-12 arrow3">
                        <b-icon icon="arrow-return-right" variant="info" aria-hidden="true"/>
                        Motorista a bordo
                    </span>
                    <span class="col-sm-12 arrow4">
                        <b-icon icon="arrow-return-right" variant="info" aria-hidden="true"/>
                        Km percorrida
                    </span>
                    <span class="col-sm-12 arrow5">
                        <b-icon icon="arrow-return-right" variant="info" aria-hidden="true"/>
                        E muito mais!
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="js">
import Vue from 'vue'
export default Vue.extend({
    data(){
        return {
            imgAtual: 'radio2',
        }
    },
    methods:{
        touchLoco(lado){
            // <- right
            //    left ->
            if(lado == 'right'){
                if(this.imgAtual == 'radio2'){
                    this.imgAtual = 'radio1'
                    this.$refs.radio1.checked = true
                }else if(this.imgAtual == 'radio1'){
                    this.imgAtual = 'radio3'
                    this.$refs.radio3.checked = true
                }else if(this.imgAtual == 'radio3'){
                    this.imgAtual = 'radio2'
                    this.$refs.radio2.checked = true
                }
            }else{
                if(this.imgAtual == 'radio1'){
                    this.imgAtual = 'radio2'
                    this.$refs.radio2.checked = true
                }else if(this.imgAtual == 'radio2'){
                    this.imgAtual = 'radio3'
                    this.$refs.radio3.checked = true
                }else if(this.imgAtual == 'radio3'){
                    this.imgAtual = 'radio1'
                    this.$refs.radio1.checked = true
                }
            }
        },

        imgClick(event){
            this.$refs[event].checked = true
        },

        handleScroll(){
            const els = document.querySelectorAll('.texto')
            els.forEach((el)  => {
                if(el.getBoundingClientRect().top <= 750){
                    var arrow1 = document.querySelectorAll('.arrow1')
                    var arrow2 = document.querySelectorAll('.arrow2')
                    var arrow3 = document.querySelectorAll('.arrow3')
                    var arrow4 = document.querySelectorAll('.arrow4')
                    var arrow5 = document.querySelectorAll('.arrow5')
                    setTimeout(() => {
                        arrow1.forEach((arrow) => {
                            arrow.style.animationPlayState = 'running'
                        })
                    }, 100);
                    setTimeout(() => {
                        arrow2.forEach((arrow) => {
                            arrow.style.animationPlayState = 'running'
                        })
                    }, 200);
                    setTimeout(() => {
                        arrow3.forEach((arrow) => {
                            arrow.style.animationPlayState = 'running'
                        })
                    }, 300);
                    setTimeout(() => {
                        arrow4.forEach((arrow) => {
                            arrow.style.animationPlayState = 'running'
                        })
                    }, 400);
                    setTimeout(() => {
                        arrow5.forEach((arrow) => {
                            arrow.style.animationPlayState = 'running'
                        })
                    }, 500);
                }
            })
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
})
</script>

<style lang="scss">
    #nosso-app{
        .div-texto{
            justify-content: center !important;
            align-items: center !important;
            display: flex !important;
            .texto{
                // white-space: nowrap;
                // text-align: left;
                font-size: 20px;
                .arrow1{
                    animation: arrow 0.8s cubic-bezier(.17,.67,.49,1.02);
                    animation-play-state: paused;
                    animation-fill-mode: forwards;
                }
                .arrow2{
                    animation: arrow 0.8s cubic-bezier(.17,.67,.49,1.02);
                    animation-play-state: paused;
                    animation-fill-mode: forwards;
                }
                .arrow3{
                    animation: arrow 0.8s cubic-bezier(.17,.67,.49,1.02);
                    animation-play-state: paused;
                    animation-fill-mode: forwards;
                }
                .arrow4{
                    animation: arrow 0.8s cubic-bezier(.17,.67,.49,1.02);
                    animation-play-state: paused;
                    animation-fill-mode: forwards;
                }
                .arrow5{
                    animation: arrow 0.8s cubic-bezier(.17,.67,.49,1.02);
                    animation-play-state: paused;
                    animation-fill-mode: forwards;
                }
            }
        }
        .margin-top-bastante{
            margin-top: 100px !important;
        }
        .texto-app{
            text-align: center !important;
            .titulo{
                // white-space: nowrap;
                font-family: 'nexabold';
                font-size: 30px;
            }
        }
        .texto{
            font-family: 'nexabook';
            font-size: 18px;
        }
        .images-app{
            margin-top: 30px;
            margin-bottom: 0px;
            margin-left: 0px;
            margin-right: 0px;
            display: grid;
            grid-template-rows: 500px 10px;
            grid-template-columns: 1fr 30px 30px 30px 30px 30px 1fr;
            align-items: center;
            justify-items: center;
            position: relative;
            height: 500px;
            section#carousel {
                position: absolute;
                // border: 1px solid red !important;
                grid-row: 1 / 2;
                grid-column: 1 / 3;
                width: 100vw !important;
                height: 500px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                transform-style: preserve-3d;
                perspective: 270px;
                --items: 3;
                --middle: 3;
                --position: 1;
            }
            div.item {
                // border: 1px solid red;
                position: absolute;
                width: 300px;
                height: 500px;
                --r: calc(var(--position) - var(--offset));
                --abs: max(calc(var(--r) * -1), var(--r));
                transition: all 0.25s linear;
                transform: rotateY(calc(-10deg * var(--r)))
                    translateX(calc(-300px * var(--r)));
                z-index: calc((var(--position) - var(--abs)));
            }
            div.item:nth-of-type(1) {
                --offset: 1;
            }
            div.item:nth-of-type(2) {
                --offset: 2;
            }
            div.item:nth-of-type(3) {
                --offset: 3;
            }
            input:nth-of-type(1) {
                opacity: 0;
                display: none;
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }
            input:nth-of-type(1):checked ~ section#carousel {
                --position: 1;
            }
            input:nth-of-type(2) {
                opacity: 0;
                display: none;
                grid-column: 2 / 3;
                grid-row: 2 / 3;
            }
            input:nth-of-type(2):checked ~ section#carousel {
                --position: 2;
            }
            input:nth-of-type(3) {
                opacity: 0;
                display: none;
                grid-column: 3 /3;
                grid-row: 3 / 3;
            }
            input:nth-of-type(3):checked ~ section#carousel {
                --position: 3;
            }
        }
    }
@keyframes arrow {
    to{
        transform: translateY(0);
        opacity: 1;
    }
    from{
        transform: translateY(-100%);
        opacity: 0;
    }
}
</style>