<template>
    <div id="increaseProfits" class="col-sm-12 justify-content-center nopadding row">
        <div class="wrap col-sm-9">
            <span class="titulo-frase">
                {{texto1}} <br>
                    <span class="cor-azul fonte-mais">
                        {{texto2}}
                    </span>
                    {{texto3}}
                <span class="cor-azul fonte-menos">
                    {{texto4}}
                </span>
            </span>
        </div>
        <div class="col-sm-10 row div-desafio">
            <div class="col-sm-12 estilo-desafio text-center">
                <span class="col-sm-12 text-center titulo-desafio">
                    Você se identifica com algum desses desafios?
                    <br><br>
                </span>
                <div class="col-sm-12 row rotate-desafio">
                    <div class="col-lg-6 col-md-12 row caixa-desafio animation-rotate text-left">
                        <div class="col-sm-2 borda-teste nopadding cursor" v-on:click="clickDiv('eaglefuel')">
                            <img 
                                width="100" 
                                height="80" 
                                src="@/assets/img-icon/combustivel-07.png"
                                alt="Pistola de combustível"
                            />
                        </div>
                        <div class="col-sm-10 borda-teste cursor " v-on:click="clickDiv('eaglefuel')">
                            <div class="texto-width">
                                Não sei como ter controle do gasto de combustível da minha frota.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 row caixa-desafio animation-rotate text-left">
                        <div class="col-sm-2 nopadding cursor" v-on:click="clickDiv('eaglecity')">
                            <img 
                                width="100" 
                                height="80"  
                                src="@/assets/img-icon/relogio-02.png"
                                alt="Relógio"
                            />
                        </div>
                        <div class="col-sm-10 cursor" v-on:click="clickDiv('eaglecity')">
                            <div class="texto-width">
                                Não consigo controlar o horário, linhas e escalas da minha frota.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 row caixa-desafio animation-rotate text-left">
                        <div class="col-sm-2 nopadding cursor" v-on:click="clickDiv('eaglecoldmanager')">
                            <img 
                                width="100" 
                                height="80" 
                                src="@/assets/img-icon/temp-05.png"
                                alt="Termômetro"
                            />
                        </div>
                        <div class="col-sm-10 cursor" v-on:click="clickDiv('eaglecoldmanager')">
                            <div class="texto-width">
                                Preciso controlar a temperatura dos meus produtos enquanto estão sendo transportados.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 row caixa-desafio animation-rotate text-left">
                        <div class="col-sm-2 nopadding cursor" v-on:click="clickDiv('eaglejob')">
                            <img 
                                width="100" 
                                height="80"  
                                src="@/assets/img-icon/caminhao.png"
                                alt="Caminhão"
                            />
                        </div>
                        <div class="col-sm-10 cursor" v-on:click="clickDiv('eaglejob')">
                            <div class="texto-width">
                                Tenho dificuldade de acompanhar as horas trabalhadas dos colaboradores.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 row caixa-desafio animation-rotate text-left">
                        <div class="col-sm-2 nopadding cursor" v-on:click="clickDiv('eaglefinder')">
                            <img 
                                width="100" 
                                height="80" 
                                src="@/assets/img-icon/rota-04.png"
                                alt="Uma rota com 2 pontos destacados"
                            />
                        </div>
                        <div class="col-sm-10 cursor" v-on:click="clickDiv('eaglefinder')">
                            <div class="texto-width">
                                Não tenho como controlar e visualizar a minha frota em tempo real.
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 row caixa-desafio animation-rotate text-left">
                        <div class="col-sm-2 nopadding cursor" v-on:click="clickDiv('eagleway')">
                            <img 
                                width="100" 
                                height="80" 
                                src="@/assets/img-icon/caixa-04.png"
                                alt="Uma caixa sendo passada de uma mão para outra mão"
                            />
                        </div>
                        <div class="col-sm-10 cursor " v-on:click="clickDiv('eagleway')">
                            <div class="texto-width">
                                Meus colaboradores têm dificuldade em saber 
                                a melhor rota para fazer entrega dos produtos.
                            </div>
                        </div>
                    </div>
                    <span class="col-sm-12 text-center titulo-desafio">
                        <br>
                        A Eagle Track tem a solução ideal para você!
                    </span>
                </div>
                <div class="col-sm-12 justify-content-center nopadding row">
                    <div class="col-sm-8 divTitleText">
                        Por que contratar a 
                        <span class="cor-azul">
                            Eagle Track?
                        </span>
                    </div>
                    <div class="col-sm-4">
                        <button class="buttonContact" @click="irParaContato">
                            Quero contratar
                        </button>
                    </div>
                    <div class="col-sm-10 justify-content-center row div-img-contratar">
                        <div class="col-sm-12 divImageWhy">
                            <img 
                                src="@/assets/images/porQueContratar.png" 
                                class="imageWhyHire" 
                                alt="Imagem mostrando alguns dos muitos motivos para contratar a Eagle Track"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-sm-12 row mt-5" id="aparelhos">
            <div class="col-sm-3 text-rigth" id="text-plataforma">
                <span class="titulo-frase">
                    disponivel em todas as
                    <span class="cor-azul fonte-mais">
                        plataformas,
                    </span>
                    para você acessar de
                    <span class="cor-azul fonte-menos">
                        onde quiser
                    </span>
                </span>
            </div>
            <div class="col-sm-3">
                <div class="container">
                    <div class="device" :class="classAnimation"/>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script lang="js">
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            classAnimation:'iphone',
            destruir:true,
            controle:true,

            texto1:'',
            texto2:'',
            texto3:'',
            texto4:'',
        };
    },
    methods:{
        irParaContato(){
            // window.scroll(0, 1)
            this.$router.push('/contato')
        },
        // value
        clickDiv(){
            // window.scroll(0, 1)
            // this.$router.push(`/${value}`)
        },
        handleScroll(){
            var textoSplit1 = ('Desenvolvemos softwares que buscam automatizar processos nos clientes,').split('')
            var textoSplit2 = ('reduzindo custos').split('')
            var textoSplit4 = ('aumentando os seus lucros.').split('')
            
            const els = document.querySelectorAll('.texto')
            this.chamaAnimationRotate()
            els.forEach(async (el) => {
                if((el.getBoundingClientRect().top <= 650) && this.controle){
                    this.controle = false
                    for (var index in textoSplit1) {
                        this.texto1 = this.texto1+textoSplit1[index]
                        await sleep(50);
                    }
                    for (var indexx in textoSplit2) {
                        this.texto2 = this.texto2+textoSplit2[indexx]
                        await sleep(50);
                    }
                    this.texto3 = 'e'
                    for (var indexxx in textoSplit4) {
                        this.texto4 = this.texto4+textoSplit4[indexxx]
                        await sleep(50);
                    }
                }
            })
            function sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        },
        chamaAnimationRotate(){
            const div = document.querySelectorAll('.rotate-desafio')
            const rota = document.querySelectorAll('.animation-rotate')
            div.forEach((rotate) => {
                if(rotate.getBoundingClientRect().top <= 580){
                    rota.forEach(element => {
                        element.style.animationPlayState = 'running'
                    });
                }
            })
            
        },
        async trocaClasse(){
            // 'mini', 'ipad',
            let devices = ['iphone', 'macbook', 'imac']
            if(this.destruir){
                for(const index in devices){  
                    this.classAnimation = devices[index]
                    await sleep(1000);
                }
                this.trocaClasse()
            }else{
                this.destruir = false
            }
            function sleep(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
        }
    },
    created() {
        this.destruir = true
        // this.trocaClasse()
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        this.destruir = false
        window.removeEventListener('scroll', this.handleScroll);
    }
    
})
</script>

<style lang="scss">
    #increaseProfits{
        padding-top: 30px !important;
        text-align: left;
        

        .animation-rotate{
            animation: rotate 1.5s cubic-bezier(.17,.67,.49,1.02);
            animation-play-state: paused;
            animation-fill-mode: forwards;
        }

        @keyframes rotate{
            to{
                transform:rotateX(0deg);
            }from{
                transform:rotateX(90deg);
            }
        }

        #text-plataforma{
            padding: 10px;
            white-space: unset;
            text-align: center !important;
            font-family: nexabold;
            font-size: 20px;
        }

        // codigo copiado de https://uicookies.com/css-slideshow/
        // https://codepen.io/comehope/pen/gBKWdW?editors=1010
        #aparelhos {
            margin: 0;
            // height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #aaa;
        }

        .container {
            width: 360px;
            height: 350px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .device,
        .device::before,
        .device::after {
            transition: 0.4s cubic-bezier(0.5, 1.7, 0.5, 1.2);
        }

        .device {
            position: relative;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            background: linear-gradient(120deg, #ddd 30%, #ccc 30%);
            transform: translateY(-25%);
        }

        .device::before,
        .device::after {
            content: '';
            position: absolute;
        }

        .iphone::before,
        .mini::before,
        .ipad::before {
            width: 2px;
            height: 2px;
            border: solid #a5adbe;
            border-width: 0 12px 0 2px;
        }

        .iphone::after,
        .mini::after,
        .ipad::after {
            width: 8px;
            height: 8px;
            background-color: white;
            border-radius: 50%;
        }

        .iphone {
            width: 59px;
            height: 124px;
            border: solid #484f5e;
            border-width: 18px 4px;
            border-radius: 6px;
        }

        .iphone::before {
            top: -10px;
        }

        .iphone::after {
            bottom: -13px;
        }

        .mini {
            width: 93px;
            height: 138px;
            border: solid #484f5e;
            border-width: 14px 5px;
            border-radius: 10px;
        }

        .mini::before {
            top: -8px;
        }

        .mini::after {
            bottom: -11px;
        }

        .ipad {
            width: 134px;
            height: 176px;
            border: solid #484f5e;
            border-width: 18px 13px;
            border-radius: 12px;
        }

        .ipad::before {
            top: -10px;
        }

        .ipad::after {
            bottom: -13px;
        }

        .macbook {
            width: 234px;
            height: 155px;
            border: 8px solid #484f5e;
            border-radius: 7px 7px 0 0;
        }

        .macbook::before {
            width: 294px;
            height: 14px;
            background-color: #e8ebf0;
            top: calc(100% + 8px);
            border-radius: 0 0 14px 14px;
        }

        .macbook::after {
            width: 3px;
            height: 3px;
            background-color: #a5adbe;
            top: -6px;
            border-radius: 50%;
        }

        .imac {
            width: 360px;
            height: 215px;
            box-shadow: 
                inset 0 14px #484f5e,
                inset 14px 0 #484f5e,
                inset -14px 0 #484f5e;
            border-bottom: 33px solid #e8ebf0;
            border-radius: 10px;
        }

        .imac::before {
            width: 90px;
            height: 0;
            top: calc(100% + 33px);
            border: solid transparent;
            border-bottom-color: #e2e4e8;
            border-width: 0 10px 47px 10px;
        }

        .imac::after {
            width: 4px;
            height: 4px;
            background-color: #a5adbe;
            top: 5px;
            border-radius: 50%;
            box-shadow: 0 191px 0 4px #464e5d;
        }
        ////////////////////////////////////////////////
        

        .divImageWhy{
            padding-top: 30px;
            // max-width: 100px;
            .imageWhyHire{
                min-width: 400px;
                width: 130%;
                padding-left: 35px;
                padding-right: 35px;
                transform: translateX(-14%);
            }
        }
        .div-botao-contrato{
            transform: translateY(-5vw);
            // top:45%;
            // left:calc(50% - 180px);
            // position: absolute;
        }
        .titulo-frase{
            top: 0;
            font-size: 25px;
            font-family: 'nexabold';
            .cor-azul{
                color: #14afe2;
            }
        }
        .div-contratar{
            margin-top: 50px;
            font-family: nexabold;
            font-weight: bold;
            .div-img-contratar{
                margin-top: 30px;
            }
            .cor-azul{
                color: #14afe2;
            }
        }
        .divTitleText{
            border-bottom: 5px solid #075f6f;
            font-family: 'nexabold';
            font-size: 30px;
            color: #373d43;
            .cor-azul{
                color: #14afe2;
            }
        }
        .estilo-desafio{
            margin-top: 20px;
            font-size: 25px !important;
            font-family: 'nexabook';
            span{
                margin-bottom: 100px !important;
            }
            .titulo-desafio{
                font-family: 'nexabold';
                font-size: 25px !important;
            }
        }
        .divSimpleText{
            font-size: 31px;
        }

        .divImageBackground{
            width: 100%;
        }
        .wrap {
            text-align: center;
            background-color: rgba(255, 255, 255, 0.1);
            box-shadow: 0px 0px 10px rgba(109, 114, 136, 0.897);
            margin-top: 90px;
            margin-bottom: 90px;
            font-size: 30px;
            font-family: 'nexabook';
            position:relative;
            transition: 1s;
        }
        .div-desafio{
            display: flex;
            align-items: center;
            justify-content: center;
            overflow:hidden;
        }
        .borda{
            border: 1px solid black !important;
            min-height: 180px;
            margin: 10px;
        }
        .cursor{
            cursor: pointer;
        }
        .caixa-desafio{
            font-size: 18px;
            overflow: hidden;
            // white-space: nowrap;
            // transition:5s;
            
            &:hover{
                background-color: rgba(0, 0, 0, 0.096);
                transition:.2s;
                border-bottom-width: 10px;
                border-left: 2px solid #075f6f;
            }
            .texto-width{
                text-align:justify;
                // border: 1px solid ;
                max-width: 80%;
            }
            div{
                padding:10px;
                img{
                    // transform: translateX(-1vw);
                }
            }
        }
        .flip{
            text-align:center;
        }
    }
@keyframes shine {
    to{
        background-position: 220% center;
    }
    from{
        background-position: 0% center;
    }
}
@keyframes flip {
    0%{ 
        transform: translateY(0px)      translateX(-50px); // 1
    }
    8%{ 
        transform: translateY(0px)      translateX(-50px); // 1
    }
    16%{ 
        transform: translateY(-190px)   translateX(-50px); // 2
    }
    24%{ 
        transform: translateY(-190px)   translateX(-50px); // 2
    }
    32%{ 
        transform: translateY(-380px)   translateX(-50px); // 3
    }
    40%{ 
        transform: translateY(-380px)   translateX(-50px); // 3
    }
    48%{ 
        transform: translateY(-570px)   translateX(-50px); // 4
    }
    54%{ 
        transform: translateY(-570px)   translateX(-50px); // 4
    }
    64%{ 
        transform: translateY(-760px)   translateX(-50px); // 5 
    }
    74%{ 
        transform: translateY(-760px)   translateX(-50px); // 5
    }
    80% { 
        transform: translateY(-950px)   translateX(-50px); // 6
    }
    91% { 
        transform: translateY(-950px)   translateX(-50px); // 6 
    }
    100% { 
        transform: translateY(0px)      translateX(-50px); // reset 
    }
}
@keyframes pendulo{
    0%{
        transform:translateX(40px) scale(1.4)
    }
    50%{
        transform:translateX(50px) scale(1.4)
    }
    100%{
        transform:translateX(60px) scale(1.4)
    }
}
</style>
