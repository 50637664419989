<template>
    <div class="col-sm-12 nopadding row justify-content-center row overflow-hidden">
        <bigImage class="eagle-blue margin-top"/>
        <products class="eagle-blue margin-bot-10px"/>
        <app/>
        <increaseProfits/>
        <occupationArea 
            class="eagle-white  margin-bot-10px"
        />
    </div>    
</template>
<!-- Global site tag (gtag.js) - Google Analytics -->
<script async src="https://www.googletagmanager.com/gtag/js?id=UA-100341043-1"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());  gtag('config', 'UA-100341043-1');
</script>
<script lang="js">
import Vue from 'vue'
export default Vue.extend({
    components:{
        bigImage: require('@/components/Pages/bigImage').default,
        products: require('@/components/Pages/products').default,
        app:      require("@/components/Pages/nossoApp").default,
        increaseProfits: require('@/components/Pages/IncreaseProfits').default,
        occupationArea:  require('@/components/Pages/occupationArea').default,
    }, 
})
</script>

<style lang="scss">
  .margin-top{
    margin-top:-.09%;
    // margin-top:3.70rem;
  }
  .margin-bot-10px{
    margin-top: 60px!important;
  }
  @media screen and (max-width: 799px) {
    .div-text-animated{
      top: -100px;
    }
  }
  @media screen and (min-width: 800px) {
    .div-text-animated{
      top: -100px;
    }
  }
  @media screen and (min-width: 900px) {
    .div-text-animated{
      top: -200px;
    }
  }
  @media screen and (min-width: 1100px) {
    .div-text-animated{
      top: -250px;
      
    }
  }
  @media screen and (min-width: 1200px) {
    .div-text-animated{
      top: -350px;
      
    }
  }
  @media screen and (min-width: 1500px) {
    .div-text-animated{
      top: -450px;
      
    }
  }
  @media screen and (min-width: 1900px) {
    .div-text-animated{
      top: -650px;
      
    }
  }
  .div-text-animated{
    // margin-top: 60px!important;
    // background-color: rgba(255, 255, 255, 0.1);
    // box-shadow: 0px 0px 10px rgba(109, 114, 136, 0.897);
    position:absolute;
    // top: clamp( -8%);
    color:#555;
    font-family:'nexabold';
    // font-weight:300/;
    // font-size:32px;
    // max-width:90% !important;
    // transform: translateY(-400%);
    // border:1px solid red;
    .titulo-frase{
      animation: text-clipp 2s linear;
      color: #fff;
      top: 0;
      font-size: clamp(10px, calc(0.1em + 1.4vw), 35px);
      // font-size: 30px;
      font-family: 'nexabold';
      overflow: hidden;
      .cor-azul{
        color: #14afe2;
      }
    }
  }
@keyframes text-clipp {
  to {
    opacity:1;
  }
  from {
    opacity:0;
    transform: translateY(-90%);
  }
}
</style>