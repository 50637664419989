<template>
    <div id="app">
        <div class="row">
            <headerEagle/>
            <main>
                <router-view/>
                <cookiesAlerta 
                    v-if="!aceeptCookies"
                />
            </main>
            <footerEagle/>
        </div>
    </div>
</template>

<script lang="js">
import Vue from 'vue'
export default Vue.extend({
    components:{
        headerEagle: require('@/components/HeaderFooter/headerEagle').default,
        cookiesAlerta: require('@/components/Cookies/CookiesAlerta').default,
        footerEagle: require('@/components/HeaderFooter/footerEagle').default,
    },
    data() {
        return {
            aceeptCookies: false,
            cookiesEventos: false,
        }
    },
    methods: {
        clickEvento(){
            const parsed = JSON.stringify(true)
            localStorage.setItem('partificar-evento', parsed)
            this.cookiesEventos = false
            window.open('https://hybri.live/papo_de_sucesso_eagle/setup/profile', '__target')
            this.$bvModal.hide('modal-evento')
        }
    },
    mounted(){
        this.aceeptCookies = Boolean(localStorage.getItem('cookies-accept'))
    },
})
</script>
<style lang="scss">
    a{
        color: #4889e5;
    }
    .postion-relative{
        position: relative;
    }
    .max-height-img{
        object-fit: cover;
        width: 80%;
        max-height: 400px;
    }

    .tamanho-px{
        font-size:18px;
    }

    .fundo-app-azul{
        color: #14afe2;
    }

    .tamanho-btn-max{
        max-height:90px;
        overflow: hidden;
    }

    .border-btn-geral{
        animation: animation-img-clip 2s cubic-bezier(.17,.67,.49,1.02);
        animation-play-state: running;
        animation-fill-mode: forwards;
        .divImage{
            width: 45%;
            position: relative;
            bottom: 40%;
            transition:1s;
            &:hover{
                animation-play-state: paused;
                animation: pulse 2s infinite;
                transform:scale(1.2)
            }
        }
    }
    @-webkit-keyframes pulse {
        0%{
            -webkit-box-shadow: 0 0 0 0 rgba(12, 16, 226, 0.4);
        }
        70%{
            -webkit-box-shadow: 0 0 0 40px rgba(7, 7, 7, 0);
        }
        100%{
            -webkit-box-shadow: 0 0 0 0 rgba(156, 155, 148, 0);
        }
    }
    @keyframes pulse {
        0%{
            -moz-box-shadow: 0 0 0 0 rgba(13, 63, 228, 0.4);
            box-shadow: 0 0 0 0 rgba(77, 76, 74, 0.4);
        }
        70%{
            -moz-box-shadow: 0 0 0 40px rgba(204,169,44, 0);
            box-shadow: 0 0 0 40px rgba(12, 12, 12, 0);
        }
        100%{
            -moz-box-shadow: 0 0 0 0 rgba(24, 9, 224, 0);
            box-shadow: 0 0 0 0 rgba(34, 34, 33, 0);
        }
    }
    @keyframes animation-img-clip{
        to{
            clip-path: circle(70.7% at 50% 50%);
        }
        from{
            clip-path: circle(0% at 50% 50%);
        }
    }

    .buttons {
        text-align: center;
    }
    $cyan: #3546dd;
    $dark: #FFFFFF;
    $borderW: 2px;

    .blob-btn{
        $numOfBlobs: 4;
        z-index: 1;
        position: relative;
        padding: 20px 46px;
        margin-bottom: 30px;
        text-align: center;
        text-transform: uppercase;
        color: $cyan;
        font-size: 12px;
        font-weight: bold;
        background-color: transparent;
        outline: none;
        border: none;
        transition: color 0.5s;
        cursor: pointer;
        animation: pulse 2s cubic-bezier(.17,.67,.49,1.02) infinite;
        animation-play-state: running;
        animation-fill-mode: forwards;
        &:hover{
            animation:none;
            animation-play-state: paused;
        }
        // border-radius:30px;
        
        &:before {
            content: "";
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: $borderW solid $cyan;
            // border-radius:30px;
        }
        
        &:after {
            content: "";
            z-index: -2;
            position: absolute;
            left: $borderW*1.5;
            top: $borderW*1.5;
            width: 100%;
            height: 100%;

            transition: all 0.3s 0.2s;
            // border-radius:30px;
        }
        
        &:hover {
            color: $dark;
            // border-radius:30px;
            
            &:after {
                transition: all 0.3s;
                left: 0;
                top: 0;
                // border-radius:30px;
            }
        }
        &__inner {
            z-index: -1;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background:#ffffff;
        }
        // additional container created, because in FF blobs are breaking 
        // overflow:hidden of element with svg gooey filter
        &__blobs {
            position: relative;
            display: block;
            height: 100%;
            filter: url('#goo');
        }
        
        &__blob {
            position: absolute;
            top: $borderW;
            width: 100% / $numOfBlobs;
            height: 100%;
            background: $cyan;
            border-radius: 100%;
            transform: translate3d(0,150%,0) scale(1.7);
            transition: transform 0.45s;
            
            @supports(filter: url('#goo')) {
                transform: translate3d(0,150%,0) scale(1.4);
            }
            
            @for $i from 1 through $numOfBlobs {
                &:nth-child(#{$i}) {
                left: ($i - 1) * (120% / $numOfBlobs);
                transition-delay: ($i - 1) * 0.08s;
                }
            }
            
            .blob-btn:hover & {
                transform: translateZ(0) scale(1.7);
                
                @supports(filter: url('#goo')) {
                    transform: translateZ(0) scale(1.4);
                }
            }
        }
    }


    #modal-evento{
        .modal-content{
            background-color:rgba(0, 0, 0, 0.9);
            color:#fff;
            border:none !important;
            font-family: nexabook;
        }
        .modal-header{
            font-family: nexabold;
            border:none !important;
            // display:none !important;
        }
        .modal-footer{
            border:none !important;
        }
    }


.nowarp{
    white-space: nowrap;
}
.overflow-hidden{
    overflow: hidden;
}
.cursor-pointer{
    cursor: 'pointer';
}

strong{
    font-family: nexabold;
}


@keyframes geleca{
    from, to { transform: scale(1, 1); }
    25% { transform: scale(0.97, 1.02); }
    50% { transform: scale(1.02, 0.97); }
}
.bordaError{
    animation:geleca 1s linear;
    border: 1px solid red !important;
}
@media (width: 320px){
    .marginTopResponsive{
        margin-top: 1.65em !important;
    }
}
@media (width: 375px){
    .marginTopResponsive{
        margin-top: 2.25em !important;
    }
}
@media (width: 425px){
    .marginTopResponsive{
        margin-top: 2.57em !important;
    }
}
@media (width: 768px){
    .marginTopResponsive{
        margin-top: -.22em !important;
    }
}
@font-face {
    font-family: nexabold;
    src: url('./assets/fontes/Nexa-Bold.otf');
}
@font-face {
    font-family: nexabook;
    src: url('./assets/fontes/Nexa-Book.otf');
}

#app{
    font-family: nexabook;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    .texto-azul{
        color: #14afe2;
    }
    .texto-destaque{
        font-family: nexabold;
    }
    .texto-branco{
        color: white;
    }
    .texto-vermelho{
        color: red;
    }
    .btn{
        border-radius: 0;
    }
    .btn-azul{
        width: 100%;
        height: 100%;
        font-family: nexabold;
        color: white;
        background-color: #14afe2;
    }
}

#nav {
    padding: 30px;
}

.nopadding{
    padding: 0px !important;
}

.row{
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.eagle-blue{
    background-color: #fff !important;
}
.eagle-white{
    background-color: #fff !important;
}

</style>
