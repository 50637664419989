<template>
    <div id="card-clientes" 
        class="
            col-sm-12 
            row 
            nopadding 
            mt-3 
            d-flex 
            align-items-center 
            justify-content-center
        "
    >
        <b-carousel 
            id="slideClientes" 
            background="transparent" 
            :interval="10000"  
            indicators 
            img-height="500">
            <!-- macromaq -->
            <b-carousel-slide>
                <template v-slot:img>
                    <div class="col-sm-12 nopadding d-flex align-items-center justify-content-center">
                        <div class="corpo-depoimento d-flex align-items-center justify-content-center">
                            <div class="img-depoimento">
                                <div class="img-perfil">
                                    <img 
                                        src="@/assets/images-clientes/andremacromaq.jpg" 
                                        width="100%" height="100%"
                                        alt="André Bender - Gerente de locações da Macromaq"
                                    >
                                </div>
                                <div class="img-empresa-cliente">
                                    <img 
                                        src="@/assets/images-clientes/logoEmpresaMacromaq.png" 
                                        class="img-responsive" width="100%" height="100%"
                                        alt="Logo da empresa Macromaq. Cliente da Egle Track"
                                    >
                                </div>
                            </div>
                            <div class="div-fake"/>
                            <div class="texto-depoimento row d-flex align-items-center justify-content-center">
                                <div class="text-left texto-principal mt-5">
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z" 
                                        />
                                    </svg>
                                    O sistema de rastreamento trouxe mais 
                                    <span class="texto-destaque texto-azul">
                                        praticidade no controle da frota técnica e comercial, permitindo 
                                        saber a localização exata dos colaboradores,
                                    </span> 
                                    o que resultou também em um melhor controle das multas e dos 
                                    riscos de acidentes, além de melhor visibilidade aos bens da companhia, 
                                    trazendo mais segurança ao negócios.
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z" 
                                        />
                                    </svg>
                                </div>
                                <div class="texto-secundario">
                                    <div class="row d-flex justify-content-end"> 
                                        <span class="row col-sm-6 text-left nopadding">
                                            <span class="col-sm-12 texto-destaque texto-azul">
                                                André Bender
                                            </span>
                                            <span class="small texto-destaque text-muted col-sm-12">
                                                Gerente de locações
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-carousel-slide>
            <!-- trr gral -->
            <b-carousel-slide>
                <template v-slot:img>
                    <div class="col-sm-12 nopadding d-flex align-items-center justify-content-center">
                        <div class="corpo-depoimento d-flex align-items-center justify-content-center">
                            <div class="img-depoimento">
                                <div class="img-perfil">
                                    <img 
                                        src="@/assets/images-clientes/GralPerfil.jpg" 
                                        width="100%" height="100%"
                                        alt="
                                            Jackson A. Cadore Pires - Gerente de Frota da TRR Gral Vinicius
                                            Gabriel Saggioratto - Analista de Logística e Transporte da TRR Gral
                                        "
                                    >
                                </div>
                                <div class="img-empresa-cliente">
                                    <img 
                                        src="@/assets/images-clientes/logoEmpresaTrrGral.png" 
                                        class="img-responsive" width="100%" height="100%"
                                        alt="Logo da empresa TRR Gral. Cliente da Egle Track"
                                    >
                                </div>
                            </div>
                            <div class="div-fake"/>
                            <div class="texto-depoimento row ml-5 d-flex align-items-center justify-content-center">
                                <div class="text-left texto-principal mt-5">
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z" 
                                        />
                                    </svg>
                                    Vários controles em um único sistema. <span class="texto-azul texto-destaque"> 
                                    A Eagle oferece a vantagem da jornada de trabalho </span>dos motoristas poder 
                                    ser padronizada de acordo com a particularidade da empresa, a localização dos
                                    veículos em tempo real e também a possibilidade de controles de documentação 
                                    dos veículos, manutenções, e documentação dos motoristas. Além de possuir 
                                    um suporte técnico ágil e disponível.
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z" 
                                        />
                                    </svg>
                                </div>
                                <div class="texto-secundario">
                                    <div class="row d-flex justify-content-end"> 
                                        <span class="row col-sm-6 borda-divisa text-right nopadding">
                                            <span class="col-sm-12 texto-destaque texto-azul">
                                                Jackson A. Cadore Pires 
                                            </span>
                                            <span class="small texto-destaque text-muted col-sm-12">
                                                Gerente de Frota
                                            </span>
                                        </span>
                                        <span class="row col-sm-6 text-left">
                                            <span class="col-sm-12 nopadding texto-destaque texto-azul">
                                                Vinicius Gabriel Saggioratto
                                            </span>
                                            <span class="small text-muted texto-destaque col-sm-12 nopadding"> 
                                                Analista de Logística e Transporte 
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-carousel-slide>
            <!-- irani -->
            <b-carousel-slide>
                <template v-slot:img>
                    <div class="col-sm-12 nopadding d-flex align-items-center justify-content-center">
                        <div class="corpo-depoimento d-flex align-items-center justify-content-center">
                            <div class="img-depoimento">
                                <div class="img-perfil">
                                    <img 
                                        src="@/assets/images-clientes/marlonIrani.jpg" 
                                        width="100%" height="100%"
                                        alt="Marlon Josias da Silva - Analista Administrativo da Celulose Irani"
                                    >
                                </div>
                                <div class="img-empresa-cliente">
                                    <img 
                                        src="@/assets/images-clientes/logoEmpresaIrani.png" 
                                        class="img-responsive" width="100%" height="100%"
                                        alt="Logo da empresa Celulose Irani. Cliente da Egle Track"
                                    >
                                </div>
                            </div>
                            <div class="div-fake"/>
                            <div class="texto-depoimento row ml-5 d-flex align-items-center justify-content-center">
                                <div class="text-left texto-principal mt-5">
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z" 
                                        />
                                    </svg>
                                    Com os módulos da Eagle Track foi possível ter um maior controle da quilometragem 
                                    rodada e excessos de velocidades, além de <span class="texto-destaque texto-azul"> 
                                    identificar os condutores que cometeram infrações de trânsito, </span> podendo 
                                    cobrar dos motoristas que não seguirem as medidas estipuladas pela empresa. Aderir
                                    esse serviço trouxe um maior 
                                    <span class="texto-destaque texto-azul">
                                        controle e segurança aos funcionários e a empresa.
                                    </span>
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path 
                                            fill="currentColor" 
                                            d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z"
                                        />
                                    </svg>
                                </div>
                                <div class="texto-secundario">
                                    <div class="row d-flex justify-content-end">
                                        <span class="row col-sm-6 text-left nopadding">
                                            <span class="col-sm-12 texto-destaque texto-azul">
                                                Marlon Josias da Silva
                                            </span>
                                            <span class="small texto-destaque text-muted col-sm-12">
                                                Analista Administrativo
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-carousel-slide>
            <!-- traçado -->
            <b-carousel-slide>
                <template v-slot:img>
                    <div class="col-sm-12 nopadding d-flex align-items-center justify-content-center">
                        <div class="corpo-depoimento d-flex align-items-center justify-content-center">
                            <div class="img-depoimento">
                                <div class="img-perfil">
                                    <img 
                                        src="@/assets/images-clientes/FOTOempresaTraçado.jpeg" 
                                        width="100%" 
                                        height="100%"
                                        alt="
                                            Freddy Luiz Ersi Alves - Administrativo de Obras da Traçado E
                                            Eduardo Kistemacher - Administrativo de Monitoramento da Traçado
                                        "
                                    >
                                </div>
                                <div class="img-empresa-cliente">
                                    <img 
                                        src="@/assets/images-clientes/logoEmpresaTracado.png" 
                                        class="img-responsive" 
                                        width="100%" 
                                        height="100%"
                                        alt="Logo da empresa Traçado. Cliente da Egle Track"
                                    >
                                </div>
                            </div>
                            <div class="div-fake"/>
                            <div class="texto-depoimento row ml-5 d-flex align-items-center justify-content-center">
                                <div class="text-left texto-principal mt-5">
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path fill="currentColor" 
                                            d="M10,7L8,11H11V17H5V11L7,7H10M18,7L16,11H19V17H13V11L15,7H18Z"
                                        />
                                    </svg>
                                    Agora temos o controle adequado sobre as horas trabalhadas dos motoristas, 
                                    como: horas extras e tempo de espera. <span class="texto-destaque texto-azul">
                                        A adesão do módulo trouxe redução de custo e mais segurança 
                                        para o motorista e para empresa.
                                    </span>
                                    <svg style="width:20px;height:20px" viewBox="0 0 30 30">
                                        <path fill="currentColor" 
                                            d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z"
                                        />
                                    </svg>
                                </div>
                                <div class="texto-secundario">
                                    <div class="row d-flex justify-content-end"> 
                                        <span class="row col-sm-6 borda-divisa text-right nopadding">
                                            <span class="col-sm-12 texto-destaque texto-azul">
                                                Freddy Luiz Ersi Alves
                                            </span>
                                            <span class="small texto-destaque text-muted col-sm-12">
                                                Administrativo de Obras
                                            </span>
                                        </span>
                                        <span class="row col-sm-6 text-left">
                                            <span class="col-sm-12 nopadding texto-destaque texto-azul">
                                                Eduardo Kistemacher 
                                            </span>
                                            <span class="small text-muted texto-destaque col-sm-12 nopadding"> 
                                                Administrativo de Monitoramento
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>
<script>
import Vue from 'vue'
export default Vue.extend({
    data() {
        return {
            mobile:false,
        }
    },
    // mounted() {
    //     var windowWidth = window.innerWidth
    //     // console.log(windowWidth)
    // },
})
</script>
<style lang="scss">
    #card-clientes{
        #slideClientes{
            .carousel-indicators {
                transform: translateY(35px);
                li{
                    background-color: #c5c5c5;
                    &:hover{
                        background-color: black;
                        transition: .5s;
                        transform: scale(1.2);
                    }
                }
                .active {
                    background-color: #40a8d6 !important;
                }
            }
        }
        background-color: #2c3d47;
        height: 350px;
        width: 100%;
        @media screen and (min-width: 700px){
            height: 400px;
        }
        .corpo-depoimento{
            border-radius: 20px;
            width: 75%;
            @media screen and (max-width: 1180px){
                width: 90%;
                height: 320px;
            }
            @media screen and (min-width: 700px){
                padding-right: 10px;
            }
            height: 300px;
            background-color: #FFF;
            position: relative;
            .div-fake{
                // border: 1px solid red;
                @media screen and (max-width: 799px){
                    display:none;
                }

                @media screen and (max-width: 800px){
                    width: 220px !important;
                }

                @media screen and (max-width: 960px){
                    width: 160px !important;
                }

                @media screen and (max-width: 1380px){
                    width: 120px;
                }

                @media screen and (max-width: 1440px){
                    width: 125px;
                }

                height: 80%;
            }
            .img-depoimento{
                border-radius: 10px;
                padding: 2px;
                transform: translateX(-220%);
                position: absolute;
                height: 80%;
                width: 20%;
                
                .img-perfil{
                    border-radius: 10px;
                    box-shadow: 1px 1px 20px rgba(0, 0, 0, 1);
                    width: 70%;
                    min-width: 180px;
                    height: 70%;
                    min-height: 150px;
                    z-index:100 !important;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    transition: .3s linear;
                    &:hover{
                        width: 103%;
                        min-width: 270px;
                        height: 103%;
                        min-height: 230px;
                    }
                }
                
                .img-empresa-cliente{
                    position: absolute;
                    transition: .3s linear;
                    border-radius: 10px;
                    box-shadow: 1px 1px 20px rgba(0, 0, 0, 1);
                    width: 50%;
                    min-width: 120px;
                    height: 50%;
                    min-height: 110px;
                    transform: translateX(100%) translateY(100%);
                    &:hover{
                        z-index:1000000 !important;
                        width: 103%;
                        min-width: 270px;
                        height: 103%;
                        min-height: 230px;
                        transform: translateX(-1%) translateY(-1%);
                    }
                }

                img{
                    object-fit: cover;
                    border-radius: 10px;
                }
            }
            .texto-depoimento{
                font-style: italic;
                height: 100%;
                width: 60%;
                @media screen and (max-width: 799px){
                    margin-left: 250px !important;
                    margin-right: 10px;
                }
                .texto-azul{
                    color: #14afe2;
                }
                .texto-destaque{
                    font-family: nexabold;
                }
                .texto-principal{
                    width: 100%;
                }
                .texto-secundario{
                    @media screen and (max-width: 800px){
                        font-size: 10px;
                    }
                    white-space: nowrap;
                    width: 100%;
                }
                .borda-divisa{
                    // border-right: 1px solid #838a91;
                }
            }
        }
    }
</style>